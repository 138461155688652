// import RichTextEditor from "react-rte";
// import { getColorEntity, getTextAlignClassName } from "react-rte/lib/lib/blockStyleFunctions";
import { RichTextEditor } from "@mantine/rte";
import React, { Component } from "react";
import "./editor.css";

// import {
//   getColorEntity,
//   getColorStyles,
//   getTextAlignBlockMetadata,
//   getTextAlignClassName,
//   getTextAlignStyles,
// } from 'react-rte/lib/lib/blockStyleFunctions';
let refreshKey = 1;
export default class TextEditer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: props.value,
      keyValue: props.keyValue,

      // value: props.value || true
      //   // ? RichTextEditor.createValueFromString(props.value, "html")
      //   // ? RichTextEditor.createValueFromString("<p><span style='color: #0000ff'>Hello</span> <span style='color: #ff0000'>world</span></p>", 'html', { customInlineFn: getColorEntity })
      //   ? RichTextEditor.createValueFromString("<span style='background-color:#00ddff;width:100%;height:1px' }}>       asd</span><hr>", 'html', { customInlineFn: getColorEntity })
      //   : RichTextEditor.createEmptyValue(),
    };
  }

  // getColorEntity = function getColorEntity(element, inlineCreators) {
  //
  //

  //   if (element && element.style && element.style.color) {
  //     return inlineCreators.Entity('color', { color: element.style.color });
  //   }
  //   if (element && element.style && element.style.backgroundColor) {
  //
  //     return inlineCreators.Entity('backgroundColor', { backgroundColor: element.style.backgroundColor });
  //   }
  //   return element;
  // };
  componentWillReceiveProps = (props) => {
    this.setState({
      value: props.value,
      keyValue: props.keyValue,
    });
  };
  onChange = (value) => {
    this.setState({ value });
    console.log(typeof value);
    if (this.props.onChange) {
      // Send the changes up to the parent component as an HTML string.
      // This is here to demonstrate using `.toString()` but in a real app it
      // would be better to avoid generating a string on each change.

      this.props.onChange(value, this.props.keyValue);
    }
  };
  render() {
    const body = this.state.value;
    return (
      <div className="fieldset w-100 tooltip-text-editor">
        {this.props.label && (
          <label
            className={
              this.props.error
                ? "form-label-error mylabel control-label"
                : "control-label mylabel mb-2"
            }
          >
            {this.props.label}
          </label>
        )}

        <div
          className={
            this.props.required ? "mandatory input-group" : "input-group mb-2"
          }
        >
          <div className="block_Quote w-100">
            <RichTextEditor
              defaultValue={null}
              value={body}
              key={refreshKey}
              controls={[
                ["bold", "italic", "underline", "link"],
                ["unorderedList", "h1", "h2", "h3"],
                ["sup", "sub"],
                ["alignLeft", "alignCenter", "alignRight"],
              ]}
              onChange={this.onChange}
              styles={{
                blockquote: { color: "red", display: "inline-block" },
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}
