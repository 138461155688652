import React from "react";

export default function TextField(props) {
  console.log(props);
  return (
    <div
      className={`${
        props.view === "straight" ? "d-flex align-items-center " : ""
      }`}
    >
      {props?.label && (
        <label
          className={` 
            ${
              props?.error
                ? "form-label-error  control-label "
                : "control-label mylabel "
            } 
            ${props.view === "straight" ? "col-3" : ""}
            `}
        >
          {props?.label}
       
        </label>
      )}

      <div
        className={
          props.required
            ? props.fileHeightAdjust
              ? "mandatory input-group mb-2"
              : "mandatory input-group"
            : props.fileHeightAdjust
            ? "input-group"
            : "input-group mb-2"
        }
      >
        {props.multiline ? (
          <textarea
            {...props}
            autoComplete="off"
            maxLength={props.maxLength}
            className={
              props.error
                ? `form-control errorinput textareastyle mb-2`
                : ` form-control textareastyle mb-2`
            }
          />
        ) : props.type === "file" && props.fileName ? (
          <div
            className={`${
              props.fileHeightAdjust ? "upload-field-head" : "upload-field"
            }`}
          >
            <label
              for={`${props.id} `}
              className={props.required ? `errorfile` : ``}
            >
              <input
                {...props}
                autoComplete="off"
                
                className={
                  props.error
                    ? props.fileHeightAdjust
                      ? ` form-control errorinput h-6`
                      : ` form-control errorinput mb-2`
                    : props.fileHeightAdjust
                    ? ` form-control h-6`
                    : "form-control mb-2"
                }
              />
              {props.fileName}
            </label>
          </div>
        ) : (
          <input
            {...props}
            autoComplete="off"
            className={
              props.error
                ? props.fileHeightAdjust
                  ? ` form-control errorinput`
                  : ` form-control errorinput mb-2`
                : props.fileHeightAdjust
                ? ` form-control mb-2`
                : ` form-control`
            }
          />
        )}
      </div>
    </div>
  );
}
