import React from "react";
import DatePicker, { DateObject } from "react-multi-date-picker";
import TimePicker from "react-multi-date-picker/plugins/time_picker";

class DatePickerField extends React.Component {
  constructor(props) {
    super();
    this.state = {
      toggleHeader: "",
      value: props.value,
    };
  }
  componentWillReceiveProps = (props) => {
    this.setState({ value: props.value });
  };
  handleDatePickerChange = (value) => {
    console.log(value)
    if (value !== undefined && value !== null) {
      let date = new DateObject(value).format();

      if (date) {
        this.props.onChange({ value: date, name: this.props.name });
      } else {
        this.props.onChange({ value: null, name: this.props.name });
      }
    } else {
      this.props.onChange({ values: null, name: this.props.name });
    }
  };
  render() {
    const { classes, selected } = this.props;
    const { value } = this.state;
    // console.log(this.props.value)
    return (
      <div className="">
        {this.props.label ? (
          <label
            className={
              this.props.error
                ? "form-label-error  mylabel control-label "
                : "control-label  mylabel "
            }
          >
            {this.props.label}
            {this.props.tooltip ? (
              <span className="tooltip-icon" title={this.props.tooltip}>
                <img src="./assets/images/icons/tooltip.svg" alt="tooltip" />
              </span>
            ) : (
              ""
            )}
          </label>
        ) : (
          ""
        )}
        <div
          className={
            this.props.required ? "mandatory input-group" : "input-group"
          }
        >
          <DatePicker
            {...this.props}
        // fixMainPosition
        // fixRelativePosition
        calendarPosition="bottom"
            inputClass={
              this.props.error ? "form-control formerror " : "form-control "
            }
            className="custom-calendar"
            // placeholder={null}
            format={this.props.format ?? "DD-MM-YYYY"}
            value={this.props.value}
            editable={false}
            title={this.props.label}
            onChange={this.handleDatePickerChange}
            
            plugins = {this.props.plugins}
          />
        </div>
      </div>
    );
  }
}
export default DatePickerField;
