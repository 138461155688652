import React, { useState, useEffect } from "react";

import SearchButton from "../../../components/buttons/search/search-btn";
import CustomTable from "../../../components/table/table";
import "./view.css";
import Snackbar from "../../../components/snackbar/snackbar";
import jwtDecode from "jwt-decode";
import { user_company_list, super_comp_delete } from "../admin.api";
import Loader from "../../../components/loader/loader";
import CSVExport from "../../../components/csvExport";
import ConfirmDialog from "../../../components/actionDialogs/confirmDialog";
import {
  createdFromAdmin,
  statusRegType,
} from "../../../components/table/store";
import moment from "moment";
const adminStatus = [{ id: 0, label: "Pending" }];
export default function View(props) {
  let adminType = Number(jwtDecode(props.state.payload).user_type);
  const [view, setView] = useState(false);
  const [data, setData] = useState([]);
  const [openAction, setOpenAction] = useState(false);
  const [activeData, setActiveData] = useState({});
  const [status, setStatus] = useState(9);
  const [createdFrom, setCreateFrom] = useState(99);
  const [isActive, setIsActive] = useState(true);
  const [snackMessage, setSnackMessage] = useState("");
  const [isSnackError, setIsSnackError] = useState(false);
  const [snackOpen, setSnackOpen] = useState(false);

  let statusData = [
    { id: 9, label: "All" },
    { id: 0, label: "Pending" },
    { id: 1, label: "Submitted for Acceptance" },
    {
      id: 4,
      label: "Approve",
    },
    { id: 5, label: "Reject" },
  ];

  const handleEdit = (data) => {
    let path = "/edit-application-kerala/" + data.user_id;
    props.history.push(path);
  };
  const genDomain = (data) => {
    const newArray = [];
    data?.forEach((item, i) => {
      newArray.push(item.domain_name);
    });
    return newArray.join(", " || "");
  };
  async function fetchExportData() {
    const exportdata = [];
    data.forEach((item) => {
      exportdata.push({
        ["Company Name"]: item.company_name,
        POC: item?.person_name,
        ["POC Designation"]: item?.person_designation,
        ["Phone Number"]: item.phone_no,
        Email: item?.email,
        ["Company Address"]: item?.address,
        ["Domains"]: item?.domain_types ? genDomain(item?.domain_types) : "",
        ["Alternate Phone"]: item?.alternate_phone ?? "",
        ["Alternate Email"]: item?.alternate_email ?? "",
        ["Website Link"]: item?.site_link ?? "",
        ["Admin Created"]: item.is_admin_created === 0 ? "No" : "Yes",
        Date: item.created_at
          ? moment(item.created_at).format("DD-MMM-YYYY hh:mm A")
          : "--",
        Status:
          item.project_status === 0
            ? "Pending"
            : item.project_status === 1
            ? "Submitted"
            : item.project_status === 2
            ? "Accepted"
            : item.project_status === 3
            ? "SA Reverted"
            : item.project_status === 4
            ? "Reject"
            : item.project_status === 5
            ? "Reverted"
            : "",
      });
    });
    return exportdata;
  }
  const handleView = (data) => {
    let path = "/view-application-kerala/" + data.user_id;
    props.history.push(path);
  };
  const handleDelete = (data) => {
    setActiveData(data);
    setOpenAction(true);
  };
  const handleCancelDelete = () => {
    setOpenAction(false);
  };
  const handleDeleteConfirm = (data) => {
    setOpenAction(false);
    setIsActive(true);
    super_comp_delete(data.id)
      .then((res) => {
        setSnackMessage(res.message);
        setIsSnackError(false);
        setSnackOpen(true);
        setIsActive(false);
      })
      .catch((error) => {
        setSnackMessage(error.message);
        setIsSnackError(true);
        setSnackOpen(true);
        setIsActive(false);
      });
  };

  const handleListData = () => {
    console.log(adminType);
    setIsActive(true);
    let param = {
      type: "kerala",
      status: status === 9 ? "" : status,
      isadmin: createdFrom === 99 ? "" : createdFrom,
    };
    user_company_list(param, adminType).then((resp) => {
      setData(resp.data);
      setIsActive(false);
    });
  };
  const onSearch = () => {
    handleListData();
  };
  useEffect(() => {
    handleListData();
  }, []);
  const snackbarClose = (flag) => {
    setSnackMessage("");
    setIsSnackError(false);
    setSnackOpen(false);
    if (!flag) {
      handleListData();
    }
  };
  const handleNew = (e) => {
    props.history.push("/newrequest-application-kerala");
  };
  const handleChangeStatus = (e) => {
    let val = Number(e.target.value);
    console.log(val);
    setStatus(val);
  };
  const handleCreatedFrom = (e) => {
    let val = Number(e.target.value);
    console.log(val);
    setCreateFrom(val);
  };

  return (
    <div>
      <Loader open={isActive} />
      <Snackbar
        message={snackMessage}
        error={isSnackError}
        open={snackOpen}
        close={snackbarClose}
      />
      <div className="view-container">
        <div className="d-flex justify-content-between align-items-center">
          <h6 className="m-0">
            <b>Companies in Kerala</b>
          </h6>
          {data?.length !== 0 && (
            <CSVExport title="Companies-Kerala" fetchData={fetchExportData} />
          )}
        </div>
        <hr />

        <div className="row font-z">
          <div className="col-3 ">
            <select
              className="form-select"
              aria-label="Default select example"
              onChange={handleChangeStatus}
            >
              <option
                value={9}
                // key={key}
                selected={status}
              >
                Status
              </option>
              {statusRegType.map((item, key) => (
                <>
                  {item.id !== 6 && (
                    <option
                      value={item.id}
                      key={key}
                      selected={item.id === status}
                    >
                      {item.title}
                    </option>
                  )}
                </>
              ))}
            </select>
          </div>
          <div className="col-3 ml-2">
            <select
              className="form-select"
              aria-label="Default select example"
              onChange={handleCreatedFrom}
              placeholder="Created From"
            >
              {/* <option
                value={99}
                // key={key}
                selected={status}
              >
                Created From
              </option> */}
              {createdFromAdmin.map((ite, key) => (
                <>
                  <option
                    value={ite.id}
                    key={key}
                    selected={ite.id === createdFrom}
                  >
                    {ite.title}
                  </option>
                </>
              ))}
            </select>
          </div>
          <div className="col-1 d-flex">
            <SearchButton {...props} onClick={onSearch} />
          </div>

          {adminType === 1 && (
            <div className="col-5 d-flex justify-content-end">
              <button
                type="button"
                className=" btn btn-primary btn-sm"
                onClick={(e) => handleNew(e)}
              >
                Add New Company
              </button>
            </div>
          )}
        </div>
        <CustomTable
          {...props}
          columns={[
            { title: "Company Name", field: "company_name" },
            // { title: "Application ID", field: "id" },
            { title: "Phone Number", field: "phone_no" },
            { title: "Email", field: "email" },
            // { title: "Current Project Status", field: "projectstatus" },

            { title: "Status", field: "project_status", type: "regstatusType" },
          ]}
          data={data}
          actions={
            adminType === 2
              ? [
                  { type: "view", handleClick: handleView },
                  { type: "delete", handleClick: handleDelete },
                ]
              : [
                  { type: "view", handleClick: handleView },
                  { type: "edit", handleClick: handleEdit },
                  // { type: "delete", handleClick: handleDelete },
                ]
          }
        />
      </div>
      {openAction && (
        <ConfirmDialog
          open={openAction}
          title="Delete Company ?"
          data={activeData}
          message="Are you sure to delete this Company?"
          secondary_action="Cancel"
          primary_action="Delete"
          handleClose={handleCancelDelete}
          handleConfirm={handleDeleteConfirm}
        />
      )}
    </div>
  );
}
