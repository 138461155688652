import React, { useEffect, useState } from "react";
import { Carousel } from "react-bootstrap";
import PartnerSection from "../../../../components/page/home/org-structures/part";
import { public_orgstruc_list } from "../../public.api";

const partData = [
  {
    image: "/resources/logo/logo2.png",
    type: "1",
  },

  {
    image: "/DUK.jpeg",
    type: "3",
  },
  {
    image: "/ICTAK.png",
    type: "3",
  },
  {
    image: "/vssc.jpg",
    type: "3",
  },
  {
    image: "/members/ISPA.jpg",
    type: "2",
  },
  {
    image: "/members/SIA.jpg",
    type: "2",
  },
  {
    image: "/members/SIATI.png",
    type: "2",
  },
  {
    image: "/asap.png",
    type: "3",
  },
  {
    image: "/clg.jpg",
    type: "4",
  },
  {
    image: "/mcet.png",
    type: "4",
  },
  {
    image: "/sbce.jpg",
    type: "4",
  },
  {
    image: "/mbc.png",
    type: "4",
  },
  {
    image: "/wide.png",
    type: "4",
  },
  {
    image: "/companies/accurant.png",
    type: "0",
  },
  {
    image: "/companies/academic-new.jpg",
    type: "4",
  },
  { image:"/companies/aeroprecision.png", type: "0" },
  { image:"/c2.png", type: "2" },
  { image:"/ficci.png", type: "2" },
  { image:"/inaq.png", type: "2" },
  { image:"/companies/aerocaliph.png", type: "0" },
  { image:"/companies/alloysengineers.jpg", type: "0" },
  { image:"/companies/ananthtechnologies.png", type: "0" },
  { image:"/companies/ananthtechnologies.png", type: "3" },
  { image:"/companies/ariane.png", type: "0" },
  { image:"/companies/aryaelectronics.jpg", type: "0" },
  { image:"/companies/ARYA.jpg", type: "0" },
  { image:"/companies/bendraaerospace.jpg", type: "0" },
  { image:"/companies/brahmos.jpg", type: "0" },
  { image:"/companies/coreintimate.jpg ", type: "0" },
  { image:"/companies/cryoprecision.png", type: "0" },
  { image:"/companies/cryotrust.png", type: "0" },
  { image:"/companies/dcube.png", type: "0" },
  { image:"/companies/GSR.jpg", type: "0" },
  { image:"/companies/hex20.png", type: "0" },
  { image:"/companies/hirelfabs.jpeg", type: "0" },
  { image:"/companies/jocyt.png", type: "0" },
  { image:"/companies/kortas.png", type: "0" },
  { image:"/companies/mirox.jpg", type: "0" },
  { image:"/companies/misteo.png", type: "0" },
  { image:"/companies/mountengineering.png", type: "0" },
  { image:"/companies/MSTOOLROOM.jpg", type: "0" },
  { image:"/companies/nckmetals.jpg", type: "0" },
  { image:"/companies/okabasus.png", type: "0" },
  { image:"/companies/peoplessteel.jpg", type: "0" },
  { image:"/companies/riztech.png", type: "0" },
  { image:"/companies/rovonize.png", type: "0" },
  { image:"/companies/sifl.png", type: "0" },
  { image:"/companies/skylar.png", type: "0" },
  { image:"/companies/spacelabs.png", type: "0" },
  { image:"/companies/thinkpalm.png", type: "0" },
  { image:"/companies/thomasaero.jpg", type: "0" },
  { image:"/companies/toonz.jpg", type: "0" },
  { image:"/companies/travancoreaerospace.png", type: "0" },
  { image:"/companies/unicorp.png", type: "0" },
  { image:"/companies/UniqueSolution.png", type: "0" },
  { image:"/companies/Vaimanik.jpg", type: "0" },
  { image:"/companies/Vashishta.png", type: "0" },
  { image:"/companies/vikranthaerospace.png", type: "0" },
  { image:"/companies/vinvish.png", type: "0" },
  // {
  //   image: "/vssc.jpg",
  //   type: "4",
  // },
  // {
  //   image: "/vssc.jpg",
  //   type: "3",
  // },
];
// const partData = [
//   {
//     image: "/logo.png",
//     type: "0",
//   }, ]
export default function Partners(props) {
  const [data, setdata] = useState([]);
  const [activeItem, setActiveItem] = useState("0");
  const [url, setUrl] = useState("");
  const [isActive, setIsActive] = useState(false);
  const [key, setKey] = useState(1);
  // const handleOrgList = () => {
  //   setIsActive(true);
  //   public_orgstruc_list().then((res) => {
  //     setdata(res.data);
  //     setUrl(res.current_url);
  //     setIsActive(false);
  //   });
  // };
  // useEffect(() => {
  //   handleOrgList();
  // }, []);
  //
  console.log(activeItem);
  return (
    <div className="org-structure">
      <div className="org-strecture-title">
        {props.state.language === "ma" ? "ഞങ്ങളുടെ പങ്കാളികൾ" : "Our Partners"}
      </div>
      <div className="strec-cont-partners">
        <div className="tab-cont-cont-partners">
          <div className="tab-cont-partners">
            <div
              className={
                activeItem === "0"
                  ? "tab-switch-partners activeTab"
                  : "tab-switch-partners"
              }
              onClick={(e) => {
                setActiveItem("0");
                setKey(key + 1);
              }}
            >
              <div>
                {props.state.language === "ma"
                  ? "അനുബന്ധ കമ്പനികൾ"
                  : "Affiliated Companies"}
              </div>
            </div>
            <div
              className={
                activeItem === "1"
                  ? "tab-switch-partners activeTab"
                  : "tab-switch-partners"
              }
              onClick={(e) => {
                setActiveItem("1");
                setKey(key + 1);
              }}
            >
              <div>
                {props.state.language === "ma"
                  ? "കെസ്പേസിലെ കമ്പനികൾ"
                  : "Companies in KSPACE Premises"}
              </div>
            </div>
            <div
              className={
                activeItem === "3"
                  ? "tab-switch-partners activeTab"
                  : "tab-switch-partners"
              }
              onClick={(e) => {
                setActiveItem("3");
                setKey(key + 1);
              }}
            >
              <div>
                {props.state.language === "ma"
                  ? "ഞങ്ങളുടെ എംഒയുകൾ"
                  : "Our MoUs"}
              </div>
            </div>
            <div
              className={
                activeItem === "4"
                  ? "tab-switch-partners activeTab"
                  : "tab-switch-partners"
              }
              onClick={(e) => {
                setActiveItem("4");
                setKey(key + 1);
              }}
            >
              <div>
                {props.state.language === "ma"
                  ? "വിദ്യാഭ്യാസ സ്ഥാപനങ്ങൾ"
                  : "Academic Institutions"}
              </div>
            </div>
            <div
              className={
                activeItem === "2"
                  ? "tab-switch-partners activeTab"
                  : "tab-switch-partners"
              }
              onClick={(e) => {
                setActiveItem("2");
                setKey(key + 1);
              }}
            >
              <div>
                {props.state.language === "ma" ? "അംഗത്വം" : "Membership"}
              </div>
            </div>

            {/* <div
              className={
                activeItem === "2" ? "tab-switch-partners activeTab" : "tab-switch-partners"
              }
              onClick={(e) => setActiveItem("2")}
            >
              <div>
                {props.state.language === "ma" ? "കോർ ടീം" : "Core Team"}
              </div>
              <div>
                <hr
                  className={
                    activeItem === "2"
                      ? "org-border-active"
                      : "org-border-inactive"
                  }
                />
              </div>
            </div> */}
          </div>
        </div>

        <div className="strec-item-cont">
          <PartnerSection
            {...props}
            data={partData}
            fullwidth={true}
            active={activeItem}
            isActive={isActive}
            key={key}
          />
        </div>
      </div>
    </div>
  );
}
