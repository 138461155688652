import React, { useState, useEffect } from "react";

import SearchButton from "../../../components/buttons/search/search-btn";
import ConfirmDialog from "../../../components/actionDialogs/confirmDialog";
import CustomTable from "../../../components/table/table";
import "./view.css";
import CSVExport from "../../../components/csvExport";
import {
  get_manPowerList_list,
  admin_expman_delete,
  get_candidates_list,
  admin_cand_delete,
  admin_candidates_delete,
} from "../admin.api";
import Loader from "../../../components/loader/loader";
import Snackbar from "../../../components/snackbar/snackbar";
import jwtDecode from "jwt-decode";
import moment from "moment";
export default function View(props) {
  const [data, setdata] = useState([]);
  const [view, setView] = useState(false);
  const [isActive, setIsActive] = useState(true);
  const [openAction, setOpenAction] = useState(false);
  const [activeData, setActiveData] = useState({});
  const [snackMessage, setSnackMessage] = useState("");
  const [isSnackError, setIsSnackError] = useState(false);
  const [snackOpen, setSnackOpen] = useState(false);
  let adminType = Number(jwtDecode(props.state.payload).user_type);

  const onSearch = () => {
    //
  };
  const handleAdviserList = () => {
    console.log(adminType);
    setIsActive(true);
    get_candidates_list(adminType)
      .then((res) => {
        let tdata = res.data;
        let searchData = [];
        tdata.forEach((item, index) => {
          item["slno"] = index + 1;
          // searchData.push(data[0]?.);
        });
        setdata(tdata);
        setIsActive(false);
      })
      .catch(() => {
        setIsActive(false);
      });
  };
  const handleAddNew = (row) => {
    let path = `/manpower-add`;
    props.history.push(path);
  };

  const handleView = (data) => {
    let path = `/candidates-view/${data.id}`;
    props.history.push(path);
  };

  const handleDelete = (data) => {
    setActiveData(data);
    setOpenAction(true);
  };
  const handleDeleteConfirm = (data) => {
    setOpenAction(false);
    setIsActive(true);
    admin_cand_delete(data.id)
      .then((res) => {
        setSnackMessage(res.message);
        setIsSnackError(false);
        setSnackOpen(true);
        setIsActive(false);
      })
      .catch((error) => {
        setSnackMessage(error.message);
        setIsSnackError(true);
        setSnackOpen(true);
        setIsActive(false);
      });
  };
  const snackbarClose = (flag) => {
    setSnackMessage("");
    setIsSnackError(false);
    setSnackOpen(false);
    if (!flag) {
      handleAdviserList();
    }
  };

  const handleDownload = () => {};
  const handleCancelDelete = () => {
    setOpenAction(false);
  };
  useEffect(() => {
    handleAdviserList();
  }, []);
  const handleInterest = (data) => {
    const input = data;
    const parsedArray = JSON.parse(input).map((item) => item.replace("/", " "));
    const result = parsedArray.join(", ");
    return result;
  };
  async function fetchExportData() {
    const exportdata = [];
    data.forEach((item, i) => {
      exportdata.push({
        "Sl no": i + 1,
        Name: item?.name,
        Phone: item?.contact,
        Email: item?.email,

        "Alternate Phone": item?.alt_contact,
        "Alternate Email": item?.alt_email,
        "Area of interest" : item?.interest_type,
        "Preferences": handleInterest(item?.interest),

        Address: item?.address,
        "Registration Date": moment(item?.created_at).format("DD/MM/yyyy"),

       
        
        Status:
          item.status === 0
            ? "Pending"
            : item.status === 1
            ? "Submitted"
            : item.status === 2
            ? "Approved"
            : item.status === 3
            ? "Reverted to user"
            : item.status === 4
            ? "Reverted to admin"
            : item.status === 5
            ? "Rejected"
            : "",
      });
    });
    return exportdata;
  }
  return (
    <div>
      <Loader open={isActive} />
      <Snackbar
        message={snackMessage}
        error={isSnackError}
        open={snackOpen}
        close={snackbarClose}
      />
      <div className="view-container">
        {/* <h6>
          <b>Expert Manpower</b>
        </h6> */}
        <div className="d-flex justify-content-between align-items-center">
          <h6 className="m-0">
            <b>Job Seeker Registry</b>
          </h6>
          <CSVExport title="Job Seeker Registry" fetchData={fetchExportData} />
        </div>
        <hr />
        {/* ---------admin--------------- */}
        {adminType === 1 && (
          <>
            {/* <button
              type="button"
              className="btn btn-primary btn-sm"
              onClick={(e) => handleAddNew(e)}
            >
              Add New
            </button> */}
            {/* <div className="row font-z mt-2">
              <div className="col-3 ">
                <select className="form-select" aria-label="Default select example">
                  <option selected>Select Application Status</option>
                  <option value="1">Pending</option>
                  <option value="2">Accepted</option>
                </select>
              </div>
              <div className="col-1 d-flex">
                <SearchButton {...props} onClick={onSearch} />
              </div>
            </div> */}
            <CustomTable
              {...props}
              columns={[
                { title: " Name", field: "name" },
                { title: "Email", field: "email" },
                { title: "Phone Number", field: "contact" },
                {
                  title: "Updated at",
                  field: "updated_at",
                  type: "fullDateTime",
                },
                // { title: "Area of Expertise", field: "category" },

                {
                  title: "Status",
                  field: "status",
                  type: "candidatesList",
                },
              ]}
              data={data}
              actions={[
                { type: "view", handleClick: handleView },
                // { type: "edit", handleClick: handleEdit },
                // { type: "delete", handleClick: handleDelete },
              ]}
            />
          </>
        )}
        {/* -------------super admin------ */}
        {adminType === 2 && (
          <>
            {/* <div className="row font-z"> */}
            {/* <div className="col-3 ">
                <select className="form-select" aria-label="Default select example">
                  <option selected>Select Application Status</option>
                  <option value="1">Pending</option>
                  <option value="2">Accepted</option>
                </select>
              </div>
              <div className="col-1 d-flex">
                <SearchButton {...props} onClick={onSearch} />
              </div>
            </div> */}
            <CustomTable
              {...props}
              columns={[
                { title: " Name", field: "name" },
                { title: "Email", field: "email" },
                { title: "Phone Number", field: "contact" },
                {
                  title: "Updated at",
                  field: "updated_at",
                  type: "fullDateTime",
                },
                // { title: "Area of Expertise", field: "category" },

                {
                  title: "Status",
                  field: "status",
                  type: "candidatesList",
                },
              ]}
              data={data}
              actions={[
                { type: "view", handleClick: handleView },
                { type: "delete", handleClick: handleDelete },
              ]}
            />
          </>
        )}
        {/* -------------super admin------ */}
      </div>
      {openAction && (
        <ConfirmDialog
          open={openAction}
          title="Delete Job Seeker ?"
          data={activeData}
          message="Are you sure to delete this Job Seeker?"
          secondary_action="Cancel"
          primary_action="Delete"
          handleClose={handleCancelDelete}
          handleConfirm={handleDeleteConfirm}
        />
      )}
    </div>
  );
}
