import React, { useEffect, useState, useRef, useMemo } from "react";
import { user_techprop_list } from "../user.api";
import Loader from "../../../components/loader/loader";
import { data } from "../../../layout/userlayout/components/footer/heatmap";
import TechPropTile from "./components/techPropTile";
import "./style.css";
import Pagination from "../../../components/page/companyDirectory/companyPagination";
import SearchButton from "../../../components/buttons/search/search-btn";
import { tech_transfer_data } from "../../admin/admin.api";
let PageSize = 10;
export default function TechnologyTransferProposal(props) {
  const [isActive, setIsActive] = useState(false);
  const [data, setData] = useState([]);
  const myRef = useRef(null);
  const [category , setCategory] = useState("")
  const [org , setOrg] = useState("")
  const [dropData , setDropData] = useState([])
  const [currentPage, setCurrentPage] = useState(1);
  const param = {
    ...(category
      ? { category_id: category }
      : {}),
    ...(org
      ? { organization_id: org }
      : {}),
    
  };
  const getData = (param) => {
    setIsActive(true);
    user_techprop_list(param).then((res) => {
      setData(res.data);
      setIsActive(false);
    }).catch((err) => {
      setIsActive(false);
    });
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
    myRef.current.scrollIntoView();
  };

  const mainData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return data.slice(firstPageIndex, lastPageIndex);
  }, [data, currentPage]);

  useEffect(() => {
    getData();
  }, []);
  const getDropdownData = () => {
    tech_transfer_data()
      .then((res) => {
        setDropData(res.data);
      })
      
  };
  useEffect(() => {
    getDropdownData();
  }, []);
  return (
    <div ref={myRef}>
      <div className="">
        <Loader open={isActive} />
        <div>
          <div className="header-banner">
            <div className="tit-txt">
              {props.state.language === "ma"
                ? "സാങ്കേതിക മാറ്റം സംബന്ധിച്ച നിർദ്ദേശങ്ങളുടെ ഡയറക്ടറി"
                : "Directory of Technology Transfer Proposals"}
            </div>
          </div>
          <div className="container">
            <div className="row mt-3">
              <div className="col-3">
                <div className="col-xs-12 col-sm-12">
                  <div className="header-label mb-1">Organization</div>
                  <select
                    className="form-select selectbox mandatory-select "
                    // required
                    name="org"
                    value={org ?? ""}
                    aria-label="Default select example"
                    // onChange={(e) => selectFieldChange(e)}
                    onChange={(e) => {
                      setOrg(e.target.value);
                    }}
                  >
                    <option value="">-- Select--</option>
                    {dropData?.map(
                      (item, i) =>
                        item.type == "organization" &&
                        item.name !== "Others" && (
                          <option value={item.id} key={i}>
                            {item.name}
                          </option>
                        )
                    )}
                  </select>
                </div>
              </div>

              <div className="col-3">
                <div className="col-xs-12 col-sm-12">
                  <div className="header-label mb-1">Category</div>
                  <select
                    className="form-select selectbox mandatory-select "
                    // required
                    name="category"
                    value={category ?? ""}
                    aria-label="Default select example"
                    // onChange={(e) => selectFieldChange(e)}
                    onChange={(e) => setCategory(e.target.value)}
                  >
                    <option value="">-- Select --</option>
                    {dropData?.map(
                      (item, i) =>
                        item.type == "category" &&
                        item.name !== "Others" && (
                          <option value={item.id} key={i}>
                            {item.name}
                          </option>
                        )
                    )}
                  </select>
                </div>
              </div>

              <div className="col-1 ">
                <div className="h-100 d-flex align-items-end">
                  <SearchButton {...props} onClick={() => getData(param)} />
                </div>
              </div>
            </div>
            <div className="proposal-tiles-container">
              {data.length !== 0 ? (
                <>
                  {mainData.map((item) => (
                    <TechPropTile data={item} {...props} drop={dropData} />
                  ))}
                  <Pagination
                    className="pagination-bar"
                    currentPage={currentPage}
                    totalCount={data.length}
                    pageSize={PageSize}
                    onPageChange={(page) => handlePageChange(page)}
                  />
                </>
              ) : (
                <>
                  <div className="no-data">
                    <img src="resources/img/no1.png" alt="" />
                    <h4>No Records Found..!!</h4>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
