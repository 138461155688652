import React, { useState, useEffect } from "react";
import TextField from "../../../components/inputs/textfield";
import { FaSearch } from "react-icons/fa";
import { resourcePersonalList } from "../user.api";
import ResourseTile from "../../../components/page/tiles/resourse";
import "./style.css";
import Loader from "../../../components/loader/loader";
export default function ResPerList(props) {
  const [searchData, setSearchData] = useState([]);
  const [isActive, setIsActive] = useState(false);
  const servInstance = process.env.REACT_APP_BASE_URL;
  let result = servInstance.substring(0, servInstance?.length - 4);
  const listData = () => {
    resourcePersonalList().then((res) => {
      let dir = res.data;
      let finalData = [];
      dir.forEach((item) => {
        if (
          // item.expert_manpower?.is_resource_person === 1 &&
          item?.approval_status === 2
        ) {
          finalData.push(item);
        }
      });
      setIsActive(false);

      setSearchData(finalData);

      setIsActive(false);
    });
  };

  useEffect(() => {
    listData();
  }, []);

  return (
    <div className="tender bg-tender">
      <Loader open={isActive} />
      <div>
        <div className="dash-banner ">
          <div className="tit-txt">
            {props.state.language === "ma"
              ? "റിസോഴ്സ് പേഴ്‌സണൽ"
              : "Resource Personnel"}
          </div>
        </div>
      </div>
      <div className="container p-50">
        <div className="res-page-cont">
          <div className="row tender-box">
            {/* <div className="col-xs-12 col-sm-3">
              <div className="form-group has-search">
                <div className="form-control-feedback">
                  <FaSearch className="search-icon" />
                </div>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search"
                />
              </div>
            </div> */}
            <div className="new-res-tile-cont-res">
              {searchData.length !== 0 ? (
                <>
                  {searchData?.map((item) => (
                    <>
                      <ResourseTile
                        data={item}
                        {...props}
                        path={`/resource-personal/${item?.user_id}`}
                        photo={`${result}${item?.file_upload}`}
                        title={`${item?.title} . ${item?.first_name}  ${item?.last_name}`}
                        email={item?.email}
                        expIn={item?.area_of_expertise}
                      />
                    </>
                  ))}
                </>
              ) : (
                <>
                  <div className="no-data">
                    <img src="resources/img/no1.png" alt="" />
                    <h4>No Records Found..!!</h4>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
