import React, { useEffect, useState } from "react";
import CommonForm from "../../../../components/commonform/commonForm";
import CustomIcons from "../../../../components/icons/kIcons.component";
import Loader from "../../../../components/loader/loader";
// import CustomIcons from "../../../../components"
import { inter_list } from "../../user.api";
//

import "./style.css";

export default function TrainingList(props) {
  const servInstance = process.env.REACT_APP_BASE_URL;
  let result = servInstance.substring(0, servInstance?.length - 4);
  const [data, setData] = useState([]);
  const [isActive, setIsActive] = useState(true);
  const handleViewDetail = (e, item) => {
    let path = "/training-detail/" + item.id;
    props.history.push(path);
  };
  const fetchData = () => {
    setIsActive(true);
    inter_list().then((resp) => {
      let tdata = resp.data;
      setData(tdata);
      setIsActive(false);
    });
  };
  useEffect(() => {
    fetchData();
  }, []);
  return (
    <div className="proj">
      <Loader open={isActive} />
      {/* <CustomIcons iconName="phone1" iconClass="daticon" /> */}
      {/* <img src="../public/resources/pdf.png" alt="" /> */}
      <div>
        <div className="dash-banner ">
          <div className="tit-txt">
            {props.state.language === "ma"
              ? "ഇന്റേൺഷിപ്പ് / പരിശീലന അപേക്ഷാ ഡയറക്ടറി"
              : "Directory of Internship / Training Request"}
          </div>
        </div>
      </div>
      <div className="container bodybox mt-3">
        <div className="row">
          {data.length !== 0 ? (
            <>
              {data.map((item) => (
                <div className="col-md-3 col-sm-6 sam ">
                  <div className="card cardx h-100">
                    <center className=" xbody rytbody ">
                      <img
                        src={`${result}${item?.file_upload_photo}`}
                        className="pic dir-training-pic"
                        alt=""
                      />
                      <h6>{item?.full_name}</h6>
                      <p>
                        <CustomIcons iconName="mail" />
                      </p>
                      <p>{item?.email}</p>
                      <p>
                        <CustomIcons iconName="school" />
                      </p>
                      <p> {item?.institution[0]?.institution_name}</p>
                      <button
                        type="button"
                        className="btn btn-primary detailb mt-1"
                        onClick={(e) => handleViewDetail(e, item)}
                      >
                        View Details
                      </button>
                    </center>
                  </div>
                </div>
              ))}
            </>
          ) : (
            <>
              <div className="no-data">
                <img src="resources/img/no1.png" alt="" />
                <h4>No Records Found..!!</h4>
              </div>
            </>
          )}

          {/* ---------------------------------- */}
          {/* --------------------------- */}
        </div>
      </div>
    </div>
  );
}
