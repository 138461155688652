import React from "react";
import CustomIcons from "../../icons/kIcons.component";
import "./dashtile.css";

export default function DashTile(props) {
  console.log(props)
  return (
    <div
      onClick={() => props.history.push(props.link)} // Keep using history.push
      className={
        props.index % 2 === 1
          ? "dash-tile-container even"
          : "dash-tile-container"
      }
    >
      <div
        className={
          props.index % 2 === 1
            ? "dash-icon-container even"
            : "dash-icon-container"
        }
      >
        <CustomIcons
          iconName="bank"
          iconClass={props.index % 2 === 1 ? "tile-icon even" : "tile-icon"}
        />
      </div>
      <div className="dash-tile-data">
        <div className="dashdataitems">
          <div
            className={
              props.index % 2 === 1 ? "dash-tile-label even" : "dash-tile-label"
            }
          >
            {props.label}
          </div>
        </div>
        <div className="dashdataitems">
          {props.label === "MARKET RESEARCH : PUBLICATIONS" ? <div
            className={
              props.index % 2 === 1 ? "dash-tile-count even" : "dash-tile-count"
            }
          >
          
          </div> : 
          <div
            className={
              props.index % 2 === 1 ? "dash-tile-count even" : "dash-tile-count"
            }
          >
          {props.label === "MARKET RESEARCH : TENDER" ? "Active :" : "Pending :"} <span className="text-danger">{props.pending ?? 0}</span>
          </div>}
          <div
            className={
              props.index % 2 === 1 ? "dash-tile-count even" : "dash-tile-count"
            }
          >
            Total:<span className="text-primary"> {props.total ?? 0}</span>
          </div>
        </div>
      </div>
    </div>
  );
}
