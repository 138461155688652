import React, { useEffect, useState } from "react";
import CommonForm from "../../../../components/commonform/commonForm";
import CustomIcons from "../../../../components/icons/kIcons.component";
import Loader from "../../../../components/loader/loader";
import { institutionList } from "../../user.api";

import "./style.css";

export default function Addproposal(props) {
  const [isActive, setIsActive] = useState(false);
  const [data, setData] = useState([]);
  const getData = () => {
    setIsActive(true);
    institutionList()
      .then((res) => res.data)
      .then((res) => {
        setData(res);
        setIsActive(false);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="proj">
      <Loader open={isActive} />
      {/* <CustomIcons iconName="phone1" iconClass="daticon" /> */}
      {/* <img src="../public/resources/pdf.png" alt="" /> */}
      <div>
        <div className="dash-banner ">
          <div className="tit-txt">
            {props.state.language === "ma"
              ? "രജിസ്റ്റർ ചെയ്ത സ്ഥാപനങ്ങളുടെ ഡയറക്ടറി"
              : "Directory of Registered Institutions"}
          </div>
        </div>
      </div>
      <div className="container bodybox mt-3">
        <div className="row">
          {data && (
            <>
              {data.length !== 0 ? (
                <>
                  {data.map((item) => (
                    <div className="col-lg-4 col-sm-12 mb-20">
                      <div className="card cad">
                        <div className="img">
                          <div className="row">
                            <div className="rytbody">
                              <h5>
                                <b>{item.institution_name}</b>
                              </h5>
                              <p>
                                <div className="row">
                                  <div className="col-1 lok">
                                    <CustomIcons iconName="location" />
                                  </div>
                                  <div className="col-11 pl-50 d-flex align-items-center">
                                    {item.headquaters_detail}
                                  </div>
                                </div>
                              </p>
                              <p>
                                <div className="row">
                                  <div className="col-1">
                                    {" "}
                                    <CustomIcons iconName="phone" />
                                  </div>
                                  <div className="col-11 pl-50 d-flex align-items-center">
                                    {item.phone_no}
                                  </div>
                                </div>
                              </p>
                            </div>
                            <div className="col-12 cors">Courses Offered</div>
                            {/* <div className="col-1"></div> */}
                            <div className="col-12 smal">
                              <small className="">
                                {item.courses && item.courses.length !== 0 && (
                                  <>
                                    {item.courses.map((cou, ind) => (
                                      <>
                                        {" "}
                                        <>{ind !== 0 && " | "}</>
                                        <>{cou.course_name}</>
                                      </>
                                    ))}
                                  </>
                                )}
                              </small>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </>
              ) : (
                <>
                  <div className="no-data">
                    <img src="resources/img/no1.png" alt="" />
                    <h4>No Records Found..!!</h4>
                  </div>
                </>
              )}
            </>
          )}
          {/* ------------------------------- */}
        </div>
      </div>
    </div>
  );
}
