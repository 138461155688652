import moment from "moment";
import React, { useEffect, useState } from "react";
import CommonForm from "../../../../../components/commonform/commonForm";
import { EmailValidate } from "../../../../../components/formValidate";
import Snackbar from "../../../../../components/snackbar/snackbar";
import { is_email_registered } from "../../../user.api";
let reuired = [
  // "company_name",
  // "person_name",
  // "address",
  // "email",
  // "abstract_project_proposal",
  // "ex_man_power",
  // "capital_investment",
  // "upload_proposal",
];
let flag = false;
let eighteenYearsAgo = new Date();
eighteenYearsAgo = eighteenYearsAgo.setFullYear(
  eighteenYearsAgo.getFullYear() - 18
);
export default function BasicDetails(props) {
  const [formData, setFormData] = useState(props.formData);

  const [keyvalue, setKeyvalue] = useState(0);
  const [count, setCount] = useState(1);
  const [error, setError] = useState({});
  const [err, setErr] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const [isSnackError, setIsSnackError] = useState(false);
  const [snackOpen, setSnackOpen] = useState(false);
  useEffect(() => {
    setFormData(props.formData);
    setKeyvalue((k) => k + 1);
  }, [props.formData]);
  // useEffect(() => {
  //   if (!formData.expertise_area) {
  //     setFormData({
  //       ...formData,
  //       expertise_area: [
  //         {
  //           expertise_name: "",
  //         },
  //       ],
  //     });
  //   }
  // }, [formData, formData.expertise_area]);
  // const selectChange = (e) => {
  //   if(e.target.value === null || e.target.value === ""){
  //     let data = formData;
  //     data[e.target.name] = "Mr"
  //     setFormData(data)
  //   }
  // }
  const textFieldChange = (e) => {
    if (e.target.name === "email" || e.target.name === "alternate_email") {
      let erroremail = !EmailValidate(e.target.value);

      setError({ ...error, [e.target.name]: erroremail });
    }
    let name = e.target.name;

    let value = e.target.value;
    let data = formData;
    data[name] = value;
    setFormData(data);

    setKeyvalue(keyvalue + 1);
  };
  const addExpertice = (i) => {
    let data = { ...formData };

    data.expertise_area.push({
      expertise_name: "",
    });
    setFormData(data);
    setCount(count + 1);
  };
  const removeExpertice = (i) => {
    let data = { ...formData };
    data.expertise_area.splice(i, 1);
    setCount(data);
    setCount(count + 1);
  };
  const handleNextSubmit = (e) => {
    e.preventDefault();

    let maxDate = new Date(moment(new Date(eighteenYearsAgo)));
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
    let emailData = { email: formData.email };
    console.log(formData.date_of_birth, maxDate);
    if (
      moment(formData.date_of_birth, "DD-MM-YYYY") <=
      moment(maxDate, "DD-MM-YYYY")
    ) {
      if (emailRegex.test(formData?.email) === true)
        if (props.update === false) {
          is_email_registered(emailData)
            .then((res) => {
              setSnackMessage(res.message);
              setIsSnackError(false);
              // setIsActive(false);
              props.action(0, formData);
              setSnackOpen(true);
            })
            .catch((error) => {
              setSnackMessage(error.response.data.message);
              setIsSnackError(true);
              setSnackOpen(true);
              // setIsActive(false);
            });
        } else {
          props.action(0, formData);
        }
      else {
        setSnackOpen(true);
        setSnackMessage("Please enter a valid email");
        setIsSnackError(true);
      }
      // const errors = FormValidate(formData, reuired);
      // if (Object.keys(errors).length === 0) {

      // } else {
      //   setSnackMessage("Please fill all reuired fields");
      //   setIsSnackError(false);
      //   setSnackOpen(true);
      // }
    } else {
      setSnackMessage(`Please Choose a valid Date of Birth`);
      setIsSnackError(true);
      setSnackOpen(true);
    }
  };
  const handleValid = (type) => {
    let flags = false;

    if (type === "domain") {
      if (props.domainData) {
        props.domainData.forEach((item) => {
          if (item.checked) flags = true;
        });
      }
    } else if (type === "areaofinterest") {
      props.areaOfInterest.forEach((item) => {
        if (item.checked) flags = true;
      });
    } else if (type === "language") {
      props.languageData.forEach((item) => {
        if (item.checked) flags = true;
      });
    }
    return flags;
  };
  const handleCheckChange = (e, type, item) => {
    flag = false;
    if (type === "domain") {
      let data = [...props.domainData];

      data[item]["checked"] = e.target.checked;
      // setFormData({ ...formData, any_other_flag: false });

      props.setDomain(data);
    } else if (type === "areaofinterest") {
      let data = [...props.areaOfInterest];

      data[item]["checked"] = e.target.checked;
      // setFormData({ ...formData, any_other_flag: false });

      props.setAreaofInterest(data);
    } else if (type === "language") {
      let data = [...props.languageData];

      data[item]["checked"] = e.target.checked;
      props.setLanguageData(data);
      console.log(data);
    }
  };
  const handleOtherChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.checked });
  };
  const handleOtherLangChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.checked });
  };
  const handleFileChange = (e) => {
    let size = e.target.files[0].size;

    let mb = size / Math.pow(1024, 2);

    if (mb < 1.5) {
      setFormData({
        ...formData,
        [e.target.name]: e.target.files[0],
      });
    } else {
      setSnackMessage("File size is greater than 5MB");
      setIsSnackError(true);
      setSnackOpen(true);
    }
  };
  const snackbarClose = (flag) => {
    setSnackMessage("");
    setIsSnackError(false);
    setSnackOpen(false);
    
  };
  
  const numberChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    let newValue = value.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, "$1");
    let data = formData;
    data = {
      ...data,

      [name]: newValue,
    };
    setFormData(data);
  };
  const genderFieldChange = (e) => {
    let data = formData;
    data = {
      ...data,

      gender: Number(e.target.value),
    };
    setFormData(data);
  };
  const consentFieldChange = (e) => {
    let data = formData;
    data = {
      ...data,

      is_willing_to_travel: Number(e.target.value),
    };
    setFormData(data);
  };
  const handleMultiChange = (e, i) => {
    let data = { ...formData };

    data.otherLanguages[i] = {
      ...data.otherLanguages[i],
      [e.target.name]: e.target.value,
    };

    setFormData(data);
  };
  const addLanguage = (i) => {
    let data = { ...formData };

    data.otherLanguages.push({ language: "" });
    setFormData(data);
    setCount(count + 1);
  };
  const removeLanguage = (i) => {
    let data = { ...formData };
    data.otherLanguages.splice(i, 1);
    setCount(data);
    setCount(count + 1);
  };
  const handleExperticeChange = (e, i) => {
    let data = { ...formData };
    data.expertise_area[i] = {
      ...data.expertise_area[i],
      [e.target.name]: e.target.value,
    };
    setFormData(data);
  };
  const handleNumberChange = (e) => {
    let data = { ...formData };
    let name = e.target.name;
    let value = e.target.value;
    let newValue = value.replace(/[^0-9]/g, "");
    data = { ...data, [name]: newValue };
    setFormData(data);
  };
  const handleDatePickerChange = (e, name) => {
    let date = e.value;
    const dateRegex = /^[0-9]{2}-[0-9]{2}-[0-9]{4}$/;
    console.log(date);
    console.log(dateRegex.test(date));
    if (dateRegex.test(date)) {
      setErr(false);
    } else {
      setErr(true);
    }
    setFormData({ ...formData, [name]: date });
    setKeyvalue(keyvalue + 1);
  };
  return (
    <div className="">
      <form onSubmit={handleNextSubmit}>

        <div className="d-flex align-items-end col-12 inputName">
          <div className="col-2 mr-2">
            <label
              className={` 
            ${
              error
                ? "form-label-error mylabel control-label "
                : "control-label mylabel mb-2"
            } 
            
            `}
            >
              Name
            </label>
            <select
              id="title"
              className="form-select  "
              required
              label="title"
              name="title"
              defaultValue={"Mr"}
              onChange={(e) => textFieldChange(e)}
              value={formData.title ? formData.title : "Mr"}
            >
              <option value="Mr">Mr</option>
              <option value="Ms">Ms</option>
              <option value="Dr">Dr</option>
            </select>
          </div>
          <div className="d-flex col-9">
            <div className="col-7 mr-2 nameTextInput">
              <CommonForm
                fieldType="text"
                required
                name="first_name"
                placeholder="First Name"
                value={formData.first_name ?? ""}
                onChange={(e) => {
                  textFieldChange(e);
                }}
                {...props}
              />
            </div>
            <div className="col-6">
              <CommonForm
                fieldType="text"
                required
                name="last_name"
                placeholder="Last Name"
                value={formData.last_name ?? ""}
                onChange={(e) => {
                  textFieldChange(e);
                }}
                {...props}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 col-sm-6">
            <CommonForm
              fieldType="date"
              label={`Date of Birth `}
              required
              name="date_of_birth"
              maxDate={new Date(eighteenYearsAgo)}
              value={formData?.date_of_birth ?? ""}
              onChange={(e) => {
                handleDatePickerChange(e, "date_of_birth");
              }}
              {...props}
            />
            {err && (
              <div className="file-up-helper mt-1">
                Please select a valid date
              </div>
            )}
          </div>
          <div className="col-xs-12 col-sm-6 mt-3 d-flex justify-content-start align-items-center">
            <div className="control-label ">Gender :</div>
            <div className="d-flex ">
              <CommonForm
                fieldType="radio"
                label="Male"
                required
                name="gender"
                value={1}
                checked={formData?.gender == 1}
                onChange={(e) => genderFieldChange(e)}
                {...props}
              />
              <CommonForm
                fieldType="radio"
                label="Female"
                required
                name="gender"
                checked={formData?.gender == 2}
                value={2}
                onChange={(e) => genderFieldChange(e)}
                {...props}
              />
              <CommonForm
                fieldType="radio"
                label="Other"
                required
                name="gender"
                checked={formData?.gender == 3}
                value={3}
                onChange={(e) => genderFieldChange(e)}
                {...props}
              />
            </div>
          </div>
        </div>
        <div className="col-xs-12 col-sm-12 my-1">
          <CommonForm
            fieldType="text"
            label="Address"
            multiline
            required
            className="no-margin"
            name="address"
            value={formData?.address ?? ""}
            onChange={(e) => {
              textFieldChange(e);
            }}
            {...props}
          />
        </div>
        <div className="col-xs-12 col-sm-12">
          <CommonForm
            fieldType="text"
            label="Phone Number"
            disabled
            value={formData?.mobile}
            required
            name=""
            {...props}
          />
        </div>
        <label className="control-label mylabel mb-2">Alternate Phone</label>
        <div className="d-flex col-12 align-items-end ">
          <div className="col-1 ">
            <CommonForm
              fieldType="text"
              name="country_code"
              maxLength="4"
              value={formData.country_code ?? ""}
              onChange={(e) => {
                handleNumberChange(e);
              }}
              {...props}
            />
          </div>
          <div className="p-1"></div>
          <div className="col-11">
            <CommonForm
              fieldType="text"
              name="alternate_phone"
              maxLength="10"
              value={formData.alternate_phone ?? ""}
              onChange={(e) => {
                handleNumberChange(e);
              }}
              {...props}
            />
          </div>
        </div>
        <div className="col-xs-12 col-sm-12 my-1">
          <CommonForm
            fieldType="email"
            label="Email"
            // disabled
            value={formData?.email ?? ""}
            onChange={(e) => {
              textFieldChange(e);
            }}
            required
            name="email"
            {...props}
          />
        </div>

        <div className="my-2">
          <div className="header-label">Languages Known</div>
          <div
          // className="check-box-cont"
          >
            <div className="check-box-cont">
              {props.languageData &&
                props.languageData.map((item, index) => (
                  <CommonForm
                    fieldType="checkbox"
                    required={
                      !formData.any_other_lang && !handleValid("language")
                    }
                    value={item.checked}
                    checked={item.checked}
                    onChange={(e) => handleCheckChange(e, "language", index)}
                    label={item.language}
                    {...props}
                  />
                ))}

              <CommonForm
                fieldType="checkbox"
                checked={formData.any_other_lang}
                value={formData.any_other_lang}
                name="any_other_lang"
                onChange={handleOtherLangChange}
                label={"Others"}
                {...props}
              />
            </div>
            {formData.any_other_lang && (
              <>
                {formData.otherLanguages?.map((e, i) => (
                  <>
                    <div>
                      <CommonForm
                        fieldType="text"
                        label="Language"
                        required
                        name="language"
                        value={e?.language}
                        onChange={(e) => {
                          handleMultiChange(e, i);
                        }}
                        {...props}
                      />
                    </div>

                    <div className="more-btn-cnt">
                      {formData.otherLanguages.length === i + 1 && (
                        <button
                          style={{ marginLeft: 5 }}
                          type="button"
                          {...props}
                          className="btn btn-primary"
                          onClick={(e) => addLanguage(i)}
                        >
                          Add More
                        </button>
                      )}
                      {formData.otherLanguages.length > 1 && (
                        <button
                          {...props}
                          type="button"
                          className="btn btn-primary"
                          onClick={(e) => removeLanguage(i)}
                        >
                          Remove
                        </button>
                      )}
                    </div>
                  </>
                ))}
              </>
            )}
          </div>
        </div>
        <div>
          <div className="header-label">Domain Type</div>
          <div className="check-box-cont">
            {props.domainData &&
              props.domainData.map((item, index) => (
                <CommonForm
                  fieldType="checkbox"
                  value={item.checked}
                  required={!formData.any_other_flag && !handleValid("domain")}
                  checked={item.checked}
                  onChange={(e) => handleCheckChange(e, "domain", index)}
                  label={item.domain_name}
                  {...props}
                />
              ))}

            <CommonForm
              fieldType="checkbox"
              onChange={handleOtherChange}
              label="Any Other"
              name="any_other_flag"
              checked={formData.any_other_flag}
              value={formData.any_other_flag}
              {...props}
            />
          </div>
          {/* if any other field checked ,below is mandatory */}
          {formData.any_other_flag && (
            <div>
              <CommonForm
                fieldType="text"
                placeholder="Please specify the Domain.."
                required
                name="any_other_domain"
                value={formData.any_other_domain ?? ""}
                onChange={(e) => {
                  textFieldChange(e);
                }}
                {...props}
              />
            </div>
          )}
          {/* ////////////////////////////// */}
        </div>

        <div>
          {formData.expertise_area?.map((e, i) => (
            <div className="section-datas-cont mt-1">
              {/* <div className="col-xs-12 col-sm-12"> */}
              <CommonForm
                fieldType="text"
                label="Area of Expertise"
                required
                value={e?.expertise_name}
                onChange={(e) => {
                  handleExperticeChange(e, i);
                }}
                className="no-margin"
                name="expertise_name"
                {...props}
              />
              {/* </div> */}

              <div className="more-btn-cnt">
                {formData.expertise_area.length === i + 1 && (
                  <button
                    style={{ marginLeft: 5 }}
                    type="button"
                    {...props}
                    className="btn btn-primary"
                    onClick={(e) => addExpertice(i)}
                  >
                    Add More
                  </button>
                )}
                {formData.expertise_area.length > 1 && (
                  <button
                    {...props}
                    type="button"
                    className="btn btn-primary"
                    onClick={(e) => removeExpertice(i)}
                  >
                    Remove
                  </button>
                )}
              </div>
            </div>
          ))}
        </div>
       
        <div className="header-label">
          Willingness to travel within India / abroad
        </div>
        <div className="check-box-cont">
          <div className="d-flex ">
            <CommonForm
              fieldType="radio"
              label="Yes"
              required
              name="is_willing_to_travel"
              value={1}
              checked={formData?.is_willing_to_travel === 1}
              onChange={(e) => consentFieldChange(e)}
              {...props}
            />
            <CommonForm
              fieldType="radio"
              label="No"
              required
              name="is_willing_to_travel"
              checked={formData?.is_willing_to_travel === 0}
              value={0}
              onChange={(e) => consentFieldChange(e)}
              {...props}
            />
          </div>
        </div>
        {/* ///////////////////////////////////// */}
        {/* ///actions ////////////// */}
        <div className="footer-buttons">
          <button type="submit" className="primary-btn basic-submit">
            SUBMIT TO NEXT STEP
          </button>
        </div>
      </form>
      <Snackbar
        message={snackMessage}
        error={isSnackError}
        open={snackOpen}
        close={snackbarClose}
      />
    </div>
  );
}
