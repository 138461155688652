import React, { useState, useEffect } from "react";
import ConfirmDialog from "../../../components/actionDialogs/confirmDialog";
import CustomTable from "../../../components/table/table";
import "./view.css";
import { get_advisorsList_list, admin_advisors_delete } from "../admin.api";
import Loader from "../../../components/loader/loader";
import Snackbar from "../../../components/snackbar/snackbar";
import jwtDecode from "jwt-decode";

export default function View(props) {
  const [data, setData] = useState([]);
  const [view, setView] = useState(false);
  const [isActive, setIsActive] = useState(true);
  const [snackMessage, setSnackMessage] = useState("");
  const [isSnackError, setIsSnackError] = useState(false);
  const [snackOpen, setSnackOpen] = useState(false);

  const [openAction, setOpenAction] = useState(false);
  const [activeData, setActiveData] = useState({});
  let adminType = Number(jwtDecode(props.state.payload).user_type);

  const onSearch = () => {
    //
  };

  const handleAdviserList = () => {
    setIsActive(true);
    get_advisorsList_list(adminType).then((res) => {
      let tdata = res.data.map((item, index) => ({
        ...item,
        slno: index + 1,
        domain_list: item.domain_types
          ? item.domain_types.map((domain) => domain.domain_name).join(", ")
          : "N/A",
      }));
      setData(tdata?.reverse());
      setIsActive(false);
    });
  };

  useEffect(() => {
    handleAdviserList();
  }, []);

  useEffect(() => {
    return () => {
      setData([]);
    };
  }, []);

  const handleEdit = (row) => {
    let path = `/advisors-edit/${row.uuid}`;
    props.history.push(path);
  };

  const handleAddNew = () => {
    let path = "/advisors-add";
    props.history.push(path);
  };

  const handleView = (data) => {
    let path = `/advisors-view/${data.uuid}`;
    props.history.push(path);
  };

  const handleDelete = (data) => {
    setActiveData(data);
    setOpenAction(true);
  };

  const handleDeleteConfirm = (data) => {
    setOpenAction(false);
    setIsActive(true);
    admin_advisors_delete(data.uuid)
      .then((res) => {
        setSnackMessage(res.message);
        setIsSnackError(false);
        setSnackOpen(true);
        setIsActive(false);
      })
      .catch((error) => {
        setSnackMessage(error.message);
        setIsSnackError(true);
        setSnackOpen(true);
        setIsActive(false);
      });
  };

  const snackbarClose = (flag) => {
    setSnackMessage("");
    setIsSnackError(false);
    setSnackOpen(false);
    if (!flag) {
      handleAdviserList();
    }
  };

  const handleDownload = () => {};

  const handleCancelDelete = () => {
    setOpenAction(false);
  };

  return (
    <div>
      <Loader open={isActive} />
      <Snackbar
        message={snackMessage}
        error={isSnackError}
        open={snackOpen}
        close={snackbarClose}
      />
      <div className="view-container">
        <h6>
          <b>Spacepark Advisors</b>
        </h6>
        <hr />
        {/* --------------admin----- */}
        {adminType === 1 && (
          <>
            <button
              type="button"
              className="btn btn-primary btn-sm"
              onClick={(e) => handleAddNew(e)}
            >
              Add New
            </button>
            <CustomTable
              {...props}
              columns={[
                { title: "Image", field: "photo", type: "image" },
                { title: "Name", field: "name" },
                { title: "Mobile", field: "mobile" },
                { title: "Email", field: "email" },
                // { title: "Domain listing", field: "domain_list" },
                {
                  title: "Status",
                  field: "admin_status",
                  type: "advisorStatus",
                },
              ]}
              data={data}
              actions={[
                { type: "view", handleClick: handleView },
                { type: "edit", handleClick: handleEdit },
              ]}
            />
          </>
        )}
        {/* ----------------- */}
        {/* --------------admin----- */}
        {adminType === 2 && (
          <>
            <CustomTable
              {...props}
              columns={[
                { title: "Image", field: "photo", type: "image" },
                { title: "Name", field: "name" },
                { title: "Mobile", field: "mobile" },
                { title: "Email", field: "email" },
                // { title: "Domain listing", field: "domain_list" },
                {
                  title: "Status",
                  field: "super_admin_status",
                  type: "advisorStatus",
                },
              ]}
              data={data}
              actions={[
                { type: "view", handleClick: handleView },
                { type: "delete", handleClick: handleDelete },
              ]}
            />
          </>
        )}
      </div>
      {openAction && (
        <ConfirmDialog
          open={openAction}
          title="Delete Spaceparks Advisor ?"
          data={activeData}
          message="Are you sure to delete this Spaceparks Advisor?"
          secondary_action="Cancel"
          primary_action="Delete"
          handleClose={handleCancelDelete}
          handleConfirm={handleDeleteConfirm}
        />
      )}
    </div>
  );
}
