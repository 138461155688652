import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import "./view.css";
import { get_adviser_details, update_adviser_status } from "../admin.api";
import Snackbar from "../../../components/snackbar/snackbar";
import Loader from "../../../components/loader/loader";
import jwtDecode from "jwt-decode";
import Swal from "sweetalert2";
export default function View(props) {
  const [data, setData] = useState([]);
  const [isActive, setIsActive] = useState(true);
  const [snackMessage, setSnackMessage] = useState("");
  const [isSnackError, setIsSnackError] = useState(false);
  const [snackOpen, setSnackOpen] = useState(false);
  const [url, setUrl] = useState("");
  const servInstance = process.env.REACT_APP_BASE_URL;
  let result = servInstance.substring(0, servInstance?.length - 4);
  const handleEdit = () => { };
  const handleDownload = () => { };
  let adminType = Number(jwtDecode(props.state.payload).user_type);
  const selectedParam = useParams();
  const getDetails = (id) => {
    get_adviser_details(id).then((res) => {
      let tdata = res.data;

      setData(tdata);
      setIsActive(false);
    });
  };
  useEffect(() => {
    getDetails(selectedParam.id);
  }, []);
  const handleDownloadBiodata = () => {
    let path = `${result}${data.biodata}`;
    window.open(path, "_blank");
  };
  const handleAdminActions = (status) => {
    let data = { status: status };
    setIsActive(true);
    update_adviser_status(selectedParam.id, data)
      .then((res) => {
        setSnackMessage(status === 4 ? "Approved Successfully" : status === 5 ? "Rejected Successfully" : res.message);
        setIsSnackError(false);
        setSnackOpen(true);
        setIsActive(false);
      })
      .catch((error) => {
        setSnackMessage(error.message);
        setIsSnackError(true);
        setSnackOpen(true);
        setIsActive(false);
      });
  };
  const snackbarClose = (flag) => {
    setSnackMessage("");
    setIsSnackError(false);
    setSnackOpen(false);
    if (!flag) {
      props.history.push("/advisors-list");
    }
  };

  const handleRevert = (status) => {
      Swal.fire({
        inputLabel: "Message",
        input: "textarea",
        showCancelButton: false,
        confirmButtonColor: "#0A223D",
        confirmButtonText: "Submit",
        inputAttributes: {
          "aria-label": "Type your message here",
        },
        inputValidator: (value) => {
          if (!value) {
            return "Please describe the reason";
          }
        },
        showCancelButton: true,
      }).then((message) => {
        if (message.isConfirmed === true) {
          console.log(message.value);
          
         
            let fdata = {
              status: status,
              admin_message: message.value,
            
          }
  
          setIsActive(true);
          update_adviser_status(selectedParam.id,fdata)
            .then((res) => {
              setSnackMessage(res.message);
              setIsSnackError(false);
              setSnackOpen(true);
              setIsActive(false);
            })
            .catch((error) => {
              setSnackMessage(error.message);
              setIsSnackError(true);
              setSnackOpen(true);
              setIsActive(false);
            });
        }
      });
    };
  return (
    <div>
      <Snackbar
        message={snackMessage}
        error={isSnackError}
        open={snackOpen}
        close={snackbarClose}
      />
      <Loader open={isActive} />
      <div className="view-container">
        <span className="d-flex justify-content-between">
          <h6 className="mb-0 pt-2">
            <b>Spaceparks Advisors Details</b>
          </h6>
          {/*      
        <button type="button" className="btn btn-primary">
          Back to List
        </button> */}
        </span>
        <hr />
        <button
          type="button"
          className="btn btn-primary btn-sm  mb-3"
          onClick={(e) => {
            props.history.push("/advisors-list");
          }}
        >
          Back
        </button>
        <div className="data">
          <div className="row">
            <div className="col-3">Name</div>
            <div className="col-1">:</div>
            <div className="col-7">{data.name}</div>
          </div>
          <div className="row">
            <div className="col-3">Designation</div>
            <div className="col-1">:</div>
            <div className="col-7">{data.designation}</div>
          </div>
          <div className="row">
            <div className="col-3">Mobile No</div>
            <div className="col-1">:</div>
            <div className="col-7">{data.mobile}</div>
          </div>
         
          <div className="row">
            <div className="col-3">Address</div>
            <div className="col-1">:</div>
            <div className="col-7">{data.address}</div>
          </div>
          <div className="row">
            <div className="col-3">Email</div>
            <div className="col-1">:</div>
            <div className="col-7">{data.email}</div>
          </div>
          {data?.alt_mobile && data?.alt_mobile !== "undefined" &&
          <div className="row">
            <div className="col-3">Alternate Mobile No</div>
            <div className="col-1">:</div>
            <div className="col-7">{data?.alt_mobile ?? ""}</div>
          </div> }
          {data?.alt_email && data?.alt_email !== "undefined" &&
          <div className="row">
            <div className="col-3">Alternate Email</div>
            <div className="col-1">:</div>
            <div className="col-7">{data?.alt_email ?? ""}</div>
          </div> }
          <div className="row">
            <div className="col-3">Domains</div>
            <div className="col-1">:</div>
            <div className="col-7">
            {data.domain_types && data.domain_types.length > 0 ? (
      <ul>
        {data.domain_types.map((domain, index) => (
          <li key={index}>{domain.domain_name}</li>
        ))}
      </ul>
    ) : (
      <span>No Domains</span>
    )}
            </div>
          </div>

          <div className="row">
            <div className="col-3"> Photo</div>
            <div className="col-1">:</div>
            <div className="col-7">
              <img width={200} src={`${result}/${data.photo}`} alt="" />
            </div>
          </div>

          <div className="row">
            <div className="col-3">Biodata</div>
            <div className="col-1">:</div>
            <div className="col-7">
              <button
                type="button"
                className="btn  btn-dark btn-sm"
                onClick={(e) => handleDownloadBiodata(e)}
              >
                Download Biodata
              </button>
            </div>
          </div>
          {data.admin_status === 5 && data.admin_message &&
          <div className="row">
            <div className="col-3">Message from Superadmin</div>
            <div className="col-1">:</div>
            <div className="col-7">
            <div className="col-7">{data.admin_message}</div>
            </div>
          </div> }

          {adminType === 1 && (
            <div className="d-flex justify-content-center">
              {/* <button type="button" className="btn btn-approve btn-success mr-2">
              Submit for Acceptance
              </button> */}

              {/* <button type="button" className="btn btn-reject btn-danger">
              Reject 
            </button>  */}
            </div>
          )}
          {adminType === 2 && (
            <>
              {data.super_admin_status === 3 && (
                <div className="d-flex justify-content-center">
                  <button
                    type="button"
                    className="btn btn-approve btn-success mr-2"
                    onClick={(e) => handleAdminActions(4)}
                  >
                    Approve
                  </button>
                  <br />
                  <button
                    type="button"
                    className="btn btn-danger  btn-danger "
                    onClick={(e) => handleRevert(5)}
                  >
                    Revert
                  </button>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}
