import React, { useEffect, useState } from "react";
import "./admin.css";
import DashTile from "../../components/admin/dashtitle/dahtile";
import { dash_info } from "./admin.api";


const routeMapping = {
  "companies-:-kspace-premises": "/company-request-list/kspace",
  "companies-:-own_premises": "/company-request-list/own",
  "existing-kerala-companies-affliated-with-kspace": "/kerala-companies-list",
  "indian-companies": "/india-companies-list",
  "international-companies": "/international-companies-list",
  "resource_personal": "/resource-list",
  "expert-manpower": "/manpower-list",
  "technology-transfer": "/transfer-proposal-list",
  "candidate-application": "/academicinstitution-list",
  "project-proposal": "/project-proposal-list",
  "academic-institution": "/academicinstitution-list",
  "academic-project-proposals": "/institutionproposal-list",
  "intership-training": "/intershiptraining-list",
  "spacepark-advisors": "/advisors-list",
  "market-research-:-tender" : "/tender-list",
  "market-research-:-publications" : "/publication-list",
  "jobseeker-registration" : "/candidates-list",

};

export default function Admin(props) {
  const [count, setCount] = useState({});

  const handleEventList = () => {
    dash_info().then((res) => {
      let tdata = res.data;
      let formattedData = {};

      Object.keys(tdata).forEach((key) => {
        formattedData[key] = {
          total: tdata[key]?.total || 0,
          pending: tdata[key]?.pending || 0,
        };
      });

      setCount(formattedData);
    });
  };

  useEffect(() => {
    handleEventList();
  }, []);

  const data = Object.keys(count).map((key) => ({
    label: key.replace(/[-_]/g, " ").toUpperCase(), // Format label
    total: count[key]?.total,
    pending: count[key]?.pending,
    link: routeMapping[key] || "#", // Use predefined route or fallback to "#"
  }));
console.log(data)
  return (
    <div>
      <div className="admin-dash-data-tiles">
        {data.map((item, index) => (
          <DashTile
            key={index}
            label={item.label}
            pending={item.pending}
            total={item.total}
            link={item.link}
            {...props}
          />
        ))}
      </div>
    </div>
  );
}
