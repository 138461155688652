import React, { useEffect, useState } from "react";
import { Carousel } from "react-bootstrap";
import OrgStructure from "../../../../components/page/home/org-structures/org.stucture.component";
import { public_orgstruc_list, spaceParkAdvisorsList } from "../../public.api";
import List from "../../advisors/list";
import Loader from "../../../../components/loader/loader";
export default function OrgStructureComp(props) {
  const [data, setdata] = useState([]);
  const [activeItem, setActiveItem] = useState("0");
  const [url, setUrl] = useState("");
  const [isActive, setIsActive] = useState(false);
  const [aData, setAData] = useState([]);

  const handleOrgList = () => {
    setIsActive(true);
    public_orgstruc_list().then((res) => {
      setdata(res.data);
      setUrl(res.current_url);
      setIsActive(false);
    });
  };
  useEffect(() => {
    handleOrgList();
  }, []);

  const fetchEvents = () => {
    spaceParkAdvisorsList().then((res) => {
      setAData(res.data);
      // setdata(...data, res.data);
      // setUrl(res.current_url);
      setIsActive(false);
    });
  };
  useEffect(() => {
    if (activeItem == 3) {
      fetchEvents();
    }
  }, [activeItem]);
  //
  const servInstance = process.env.REACT_APP_BASE_URL;
  let result = servInstance.substring(0, servInstance?.length - 4);
  return (
    <div className="org-structure">
      {/* <div className="org-strecture-title">
        {props.state.language === "ma"
          ? "സംഘടനാ ഘടന"
          : "Organizational Structure"}
      </div> */}
      <div className="strec-cont">
        <div className="tab-cont-cont">
          <div className="tab-cont">
            <div
              className={
                activeItem === "0" ? "tab-switch activeTab" : "tab-switch"
              }
              onClick={(e) => setActiveItem("0")}
            >
              <div>
                {props.state.language === "ma" ? "ഭരണസമിതി" : "Governing Body"}
              </div>
              <div>
                <hr
                  className={
                    activeItem === "0"
                      ? "org-border-active"
                      : "org-border-inactive"
                  }
                />
              </div>
            </div>
            <div
              className={
                activeItem === "1" ? "tab-switch activeTab" : "tab-switch"
              }
              onClick={(e) => setActiveItem("1")}
            >
              <div>
                {props.state.language === "ma"
                  ? "എക്സിക്യൂട്ടീവ് കൗൺസിൽ"
                  : "Executive Council"}
              </div>
              <div>
                <hr
                  className={
                    activeItem === "1"
                      ? "org-border-active"
                      : "org-border-inactive"
                  }
                />
              </div>
            </div>
            <div
              className={
                activeItem === "3" ? "tab-switch activeTab" : "tab-switch"
              }
              onClick={(e) => setActiveItem("3")}
            >
              <div>
                {props.state.language === "ma"
                  ? "നമ്മുടെ ഉപദേശകർ"
                  : "Our Advisors"}
              </div>
              <div>
                <hr
                  className={
                    activeItem === "3"
                      ? "org-border-active"
                      : "org-border-inactive"
                  }
                />
              </div>
            </div>
            <div
              className={
                activeItem === "2" ? "tab-switch activeTab" : "tab-switch"
              }
              onClick={(e) => setActiveItem("2")}
            >
              <div>
                {props.state.language === "ma" ? "കോർ ടീം" : "Core Team"}
              </div>
              <div>
                <hr
                  className={
                    activeItem === "2"
                      ? "org-border-active"
                      : "org-border-inactive"
                  }
                />
              </div>
            </div>
          
          </div>
        </div>
        {isActive ? (
          <></>
        ) : (
          <>
            {data &&
              Object.keys(data).map((key) => (
                <React.Fragment>
                  {activeItem === key && (
                    <React.Fragment>
                      <div className="strec-item-cont">
                        <OrgStructure
                          {...props}
                          data={data[key]}
                          fullwidth={true}
                          isActive={isActive}
                        />
                      </div>
                    </React.Fragment>
                  )}
                </React.Fragment>
              ))}
            {activeItem == 3 && (
              <div className="">
                {aData?.length > 0 ? (
                  <div className="container adbox mt-3 ">
                    <>
                      {/* {aData?.filter((x) => x.is_executive_council_member === 0)?.length >
                             0 && (
                             <div className="hedp">Governing body of Kerala Spacepark</div>
                           )} */}

                      
                      <div className="row justify-content-center">
                        {aData?.map((item) => (
                          <>
                            {item.is_head_position === 0 &&
                              item.is_executive_council_member === 0 &&  item?.super_admin_status === 4 &&(
                                <div className="col-12 col-md-3">
                                  <div className="adviser-cards-container">
                                    <center>
                                      <div className=" sub">
                                        <div className="card-body ">
                                          <img
                                            src={`${result}/${item.photo}`}
                                            alt=""
                                            className="org-avatar"
                                            style={{border : "0 !important"}}
                                          />
                                          {/* <h6>
                                            <b>{item.name}</b>
                                          </h6> */}
                                          <div className="org-title">{item.name}</div>
                                          <div className="org-designation">{item.designation}</div>
                                          {/* <p>{item.designation}</p> */}
                                          {/* <small className="text-primary">{item.email}</small> */}
                                        </div>
                                      </div>
                                    </center>
                                  </div>
                                </div>
                              )}
                          </>
                        ))}
                      </div>
                    </>
                  </div>
                ) : (
                  <div className="container adbox mt-3 ">
                    {/* <div className="hedp">Governing body of Kerala Spacepark</div> */}

                    <div
                      className="d-flex justify-content-center align-items-center w-100 py-5"
                      style={{ fontSize: "18px" }}
                    >
                      No Advisors Found
                    </div>
                  </div>
                )}
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
}
