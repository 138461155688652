import React, { useEffect, useState } from "react";
import RadioSelect from "../../../components/inputs/radio";
import TextField from "../../../components/inputs/textfield";
import Snackbar from "../../../components/snackbar/snackbar";
import { EmailValidate, FormValidate } from "../../../components/formValidate";
import { admin_domains_list } from "../admin.api";
import { useParams } from "react-router-dom";

import {
  get_adviser_details,
  admin_spaceparkadvisers_edit,
} from "../admin.api";
import "./view.css";
import CommonForm from "../../../components/commonform/commonForm";
let required = [
  "mobile",
  "photo",
  "name",
  "biodata",
  "designation",
  "address",
  "email",
];
export default function AddSpaceparkAdvisors(props) {
  const [formData, setFormData] = useState({
    member: true,
    head: false,
    governing_member: true,
    council_number: false,
  });

  const selectedParam = useParams();
  const getAdviserDaetails = () => {
    get_adviser_details(selectedParam.id)
      .then((res) => res.data)
      .then((res) => {
        let data = res;
        if (res.is_executive_council_member === 0) {
          data["governing_member"] = true;
          data["council_number"] = false;
        } else if (res.is_executive_council_member === 1) {
          data["governing_member"] = false;
          data["council_number"] = true;
        }

        if (res.is_head_position === 1) {
          data["head"] = true;
          data["member"] = false;
        } else if (res.is_head_position === 0) {
          data["head"] = false;
          data["member"] = true;
        }
        setSelectedDomains(res.domain_types.map((domain) => domain.id));

        setFormData(data);
      });
  };
  useEffect(() => {
    getAdviserDaetails(selectedParam);
  }, []);

  const [snackMessage, setSnackMessage] = useState("");
  const [isSnackError, setIsSnackError] = useState(false);
  const [snackOpen, setSnackOpen] = useState(false);
  const [fileChanged, setFileChanged] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [data, setData] = useState([]);
  const [emailError, setEmailError] = useState(false);
  const handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    if (e.target.name === "email") {
      let erroremail = !EmailValidate(e.target.value);
      setEmailError(erroremail);
    }
    let data = formData;
    data = { ...data, [name]: value };
    setFormData(data);
  };

  const handlePhoneNumberChange = (e) => {
    //
    let name = e.target.name;
    let value = e.target.value;
    //
    //
    if (value.length <= 10) {
      let newValue = value.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, "$1");
      let data = formData;
      data = { ...data, [name]: newValue };
      setFormData(data);
    }
  };
  const handleFileChange = (e) => {
    let size = e.target.files[0].size;
    let type = e.target.files[0].name.split(".").pop();

    if (
      type?.toLowerCase() === "pdf" ||
      type?.toLowerCase() === "doc" ||
      type?.toLowerCase() === "docx"
    ) {
      let mb = size / Math.pow(1024, 2);

      if (mb < 5) {
        setFormData({
          ...formData,
          file_name_bio: e.target.files[0].name,
          [e.target.name]: e.target.files[0],
        });
      } else {
        setSnackMessage("File size is greater than 5MB");
        setIsSnackError(true);
        setSnackOpen(true);
      }
    } else {
      setSnackMessage("File should be in .doc / .docx / .pdf format");
      setIsSnackError(true);
      setSnackOpen(true);
    }
  };

  const handlePhotoFileChange = (e) => {
    let flag = false;
    let type = e.target.files[0].name.split(".").pop();

    if (type === "png" || type === "jpeg" || type === "jpg") {
      var reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
      reader.onload = function (e) {
        var image = new Image();
        image.src = e.target.result;

        image.onload = function () {
          var height = this.height;
          var width = this.width;

          if (height === 400 || width === 400) {
            flag = true;
          }
        };
      };
      setTimeout(() => {
        if (flag) {
          setFileChanged(true);
          setFormData({
            ...formData,
            file_name_photo: e.target.files[0].name,
            [e.target.name]: e.target.files[0],
          });
        } else {
          setSnackMessage("Incorrect image dimension");
          setIsSnackError(true);
          setSnackOpen(true);
        }
      }, 200);
    } else {
      setSnackMessage("File should be in .jpg / .jpeg / .png format");
      setIsSnackError(true);
      setSnackOpen(true);
    }
  };
  const handleCheckChange = (e) => {
    let name = e.target.name;

    let data = formData;

    if (name === "governing_member") {
      data = { ...data, governing_member: true, council_number: false };
    } else if (name === "council_number") {
      data = { ...data, governing_member: false, council_number: true };
    } else if (name === "member") {
      data = { ...data, member: true, head: false };
    } else if (name === "head") {
      data = { ...data, member: false, head: true };
    }

    setFormData(data);
  };
  console.log(formData);
  const [selectedDomains, setSelectedDomains] = useState([]); // Store selected domains

  const handleDomainCheckChange = (e, domainId) => {
    setSelectedDomains(
      (prevSelected) =>
        e.target.checked
          ? [...prevSelected, domainId] // Add domain ID if checked
          : prevSelected.filter((id) => id !== domainId) // Remove if unchecked
    );
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    const errors = FormValidate(formData, required);
    setIsActive(true);
    if (selectedDomains.length == 0) {
      setSnackMessage("Please select atleast one domain");
      setIsSnackError(true);
      setSnackOpen(true);
      setIsActive(false);
      return false;
    }
    if (formData.alt_mobile) {
      if (/^\d{10,15}$/.test(formData.alt_mobile) === false) {
        setSnackMessage("Please enter a valid alternate mobile number");
        setIsSnackError(true);
        setSnackOpen(true);
        setIsActive(false);
        return false;
      }
    }
    if (formData.alt_email && !EmailValidate(formData.alt_email)) {
      setSnackMessage("Please enter a valid alternate email");
      setIsSnackError(true);
      setSnackOpen(true);
      setIsActive(false);
      return false;
    }
    if (Object.keys(errors).length === 0) {
      if (!emailError) {
        if (formData.mobile.length === 10) {
          let id = formData.uuid;
          const form = new FormData();

          form.append("mobile", formData.mobile);
          form.append("alt_mobile", formData.alt_mobile);
          form.append("alt_email", formData.alt_email ?? "");
          form.append("email", formData.email);
          form.append("address", formData.address);
          form.append(
            "is_executive_council_member",
            formData.council_number ? 1 : 0
          );
          form.append("is_head_position", formData.head ? 1 : 0);

          form.append("photo", formData.photo);
          form.append("biodata", formData.biodata);
          form.append("designation", formData.designation);
          form.append("name", formData.name);
          selectedDomains.forEach((domainId, index) => {
            form.append(`domain_type_ids[${index}]`, domainId);
          });

          admin_spaceparkadvisers_edit(id, form)
            .then((res) => {
              setSnackMessage("Advisor Edited Successfully");
              setIsSnackError(false);
              setSnackOpen(true);
            })
            .catch((error) => {
              setSnackMessage(error.message);
              setIsSnackError(true);
              setSnackOpen(true);
            });
          // console.log(formData);
        } else {
          setSnackMessage("Enter a valid 10 digit Mobile No");
          setIsSnackError(true);
          setSnackOpen(true);
          setIsActive(false);
        }
      } else {
        setSnackMessage("Please enter a valid email address");
        setIsSnackError(true);
        setSnackOpen(true);
        setIsActive(false);
      }
    } else {
      setSnackMessage("Please fill all mandatory fields");
      setIsSnackError(true);
      setSnackOpen(true);
      setIsActive(false);
    }
  };
  const snackbarClose = (flag) => {
    setSnackMessage("");
    setIsSnackError(false);
    setSnackOpen(false);
    if (!flag) {
      props.history.push("/advisors-list");
    }
  };
  const handleEventList = () => {
    setIsActive(true);
    admin_domains_list()
      .then((res) => {
        setData(res.data);
      })
      .finally(() => {
        setIsActive(false);
      });
  };

  useEffect(() => {
    handleEventList();
  }, []);
  const domainNames = data.map((domain) => domain.domain_name).join(", ");

  console.log("Selected Domains: ", selectedDomains);
  return (
    <div className="add-per">
      <Snackbar
        message={snackMessage}
        error={isSnackError}
        open={snackOpen}
        close={snackbarClose}
      />
      <div className="view-container">
        <h6>
          <b>Edit Spaceparks Advisors</b>
        </h6>
        <hr />
        <div className="row">
          <div className="col-8 pb-1">
            <TextField
              fullwidth
              label="Name"
              required
              value={formData.name ?? ""}
              onChange={(e) => handleChange(e)}
              name="name"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
            />
          </div>
          <div className="col-8 pb-1">
            <TextField
              fullwidth
              label="Designation"
              required
              value={formData.designation ?? ""}
              onChange={(e) => handleChange(e)}
              name="designation"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
            />
          </div>
          <div className="col-8 pb-1">
            <TextField
              type="text"
              label="Email"
              maxLength={70}
              required
              value={formData.email ?? ""}
              onChange={(e) => handleChange(e)}
              name="email"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
            />
          </div>
          <div className="col-8 pb-1">
            <TextField
              type="text"
              label="Phone number"
              required
              id="mobile"
              value={formData.mobile ?? ""}
              onChange={(e) => handlePhoneNumberChange(e)}
              name="mobile"
              aria-describedby="emailHelp"
            />
          </div>
          <div className="col-8 pb-1">
            <TextField
              type="text"
              label="Alternate Phone number"
              id="alt_mobile"
              value={formData.alt_mobile ?? ""}
              onChange={(e) => handlePhoneNumberChange(e)}
              name="alt_mobile"
              aria-describedby="emailHelp"
            />
          </div>
          <div className="col-8 pb-1">
            <TextField
              type="text"
              label="Alternate Email"
              maxLength={100}
              value={formData.alt_email ?? ""}
              onChange={(e) => handleChange(e)}
              name="alt_email"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
            />
          </div>

          <div className="col-3 " style={{ display: "none" }}>
            <label for="exampleInputEmail1">Council Type</label>
            <div className="d-flex">
              <div className="mop">
                <RadioSelect
                  {...props}
                  required
                  label={"Governing"}
                  name="governing_member"
                  value={formData.governing_member}
                  checked={formData.governing_member}
                  onChange={(e) => handleCheckChange(e)}
                />
              </div>

              <div className="mop">
                <RadioSelect
                  required
                  checked={formData.council_number}
                  value={formData.council_number}
                  name="council_number"
                  {...props}
                  label={"Executive"}
                  onChange={(e) => handleCheckChange(e)}
                />
              </div>
            </div>
          </div>
          <div className="col-3 " style={{ display: "none" }}>
            <label for="exampleInputEmail1">Position</label>
            <div className="d-flex">
              <div className="mop">
                <RadioSelect
                  {...props}
                  label={"Member"}
                  checked={formData.member}
                  value={formData.member}
                  name="member"
                  onChange={(e) => handleCheckChange(e)}
                />
              </div>

              <div className="mop">
                <RadioSelect
                  {...props}
                  label={"Chief"}
                  checked={formData.head}
                  value={formData.head}
                  name="head"
                  onChange={(e) => handleCheckChange(e)}
                />
              </div>
            </div>
          </div>
          <div className="col-8">
            <TextField
              required
              label="Address"
              onChange={(e) => handleChange(e)}
              name="address"
              value={formData.address}
              aria-describedby="emailHelp"
              multiline
              fullwidth
            />
          </div>
          <div className="col-8">
            <TextField
              // type="file"
              // className="form-control form-control-sm"
              label="Photo"
              required
              id="formFileSm"
              onChange={(e) => handlePhotoFileChange(e)}
              accept=".jpg,.jpeg,.png"
              fileName={
                formData.file_name_photo
                  ? formData.file_name_photo
                  : formData.photo
              }
              name="photo"
              type="file"
            />
            <small className="pers">
              *Upload only .jpg , .jpeg , .png format and Size should be less
              than 1MB of width: 400 Height: 400
            </small>
          </div>
          <div className="col-8">
            <TextField
              // type="file"
              // className="form-control form-control-sm"
              label="Biodata"
              required
              onChange={(e) => handleFileChange(e)}
              id="formFileSm"
              name="biodata"
              accept=".pdf,.docx,.doc"
              fileName={
                formData.file_name_bio
                  ? formData.file_name_bio
                  : formData.biodata
              }
              // fileName={formData.biodata ?? ""}
              type="file"
            />
            <small className="pers">
              *Upload only .pdf , .doc, .docx , format and Size should be less
              than 5MB
            </small>
          </div>
          <div className="col-8">
            <label>Domains</label>
            <div className="check-box-cont">
              {data &&
                data.map((item, index) => (
                  <CommonForm
                    key={index}
                    fieldType="checkbox"
                    value={selectedDomains.includes(item.id)}
                    checked={selectedDomains.includes(item.id)}
                    onChange={(e) => handleDomainCheckChange(e, item.id)}
                    label={item.domain_name}
                  />
                ))}
            </div>
          </div>
          <div className="col-8">
            <center>
              <button
                type="button"
                className="btn btn-dark mt-3"
                onClick={(e) => handleSubmit(e)}
              >
                Submit for Acceptance
              </button>
            </center>
          </div>
          {/* ---------------- */}
        </div>
      </div>
    </div>
  );
}
